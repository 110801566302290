import { Component, NgZone, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Platform, ToastController } from '@ionic/angular';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private router: Router,
              private platform: Platform,
              private zone: NgZone,
              private toastController: ToastController,
              private routerModule: RouterModule,
              private translate: TranslateService) {
    this.initializeApp();
  }

  initializeApp() {
    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = data.url.split('paperless://').pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });

    this.setupDeviceLanguage();
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    await toast.present();
  }

  private async setupDeviceLanguage() {
    let languageCode = (await Device.getLanguageCode()).value;

    // languageCode = 'de-de';

    // get only the first part: 'en-GB' -> 'en'
    languageCode = languageCode.split('-')[0];

    this.translate.use(languageCode);
  }

}
